import React from 'react'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import withDisplayContext from '../../../hocs/withDisplayContext/withDisplayContext'
import { propTypeLinks } from './propTypes'
import PropTypes from 'prop-types'
import { ReactComponent as StarIcon } from '../../../assets/fontawesome/custom/stars.svg'
import { featureIsEnabled } from '../../../stores/siteStore/siteStore'
import WhatsNewSideDrawer from '../../common/WhatsNewSideDrawer/WhatsNewSideDrawer'
import Analytics from '../../../utilities/analyticsUtilities'
import cx from 'classnames'
import './HeaderMenu.scss'

class HeaderMenu extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      whatsNewOpened: false
    }
  }

  renderLinks () {
    const { links } = this.props
    return (
      <>
        <div className='HeaderMenu__linkGroup primary'>
          {this.renderLinkGroup(links.primary, 'primary')}
        </div>
        <div className='HeaderMenu__linkGroup secondary'>
          {this.renderLinkGroup(links.secondary, 'secondary')}
          {this.props.featureSettingsLoaded && featureIsEnabled('whatsNew') && this.renderWhatsNewLink('secondary')}
        </div>
      </>
    )
  }

  renderWhatsNewLink (position) {
    const toggleWhatsNew = () => {
      this.setState((prevState) => ({
        whatsNewOpened: !prevState.whatsNewOpened
      }))
    }

    const closeWhatsNewModal = () => {
      this.setState({ whatsNewOpened: false })
    }

    const trackWhatsNewClick = () => {
      Analytics.trackEvent({ event_name: 'header_click', link_type: 'link_other', text: 'Whats New' }, window.utag)
    }

    return (
      <>
        {this.state.whatsNewOpened && <WhatsNewSideDrawer show={this.state.whatsNewOpened} onHide={closeWhatsNewModal} />}
        <div key='whatsNew' className={cx('HeaderMenu__link whatsNew ', position)}>
          <span style={{ paddingRight: 6 }} className='starIcon'><StarIcon /></span>
          <div onClick={() => { trackWhatsNewClick(); toggleWhatsNew() }}>
            <div key='whatsNew' className={cx('HeaderMenu__link__text__', position)}>
              What's New
            </div>
          </div>
        </div>
      </>
    )
  }

  renderLinkGroup (linkGroup = [], position) {
    return linkGroup.map((link) => {
      const { id, name, url } = link
      return (
        <div key={id} className={cx('HeaderMenu__link ', position)}>
          <LinkHandler
            noBs
            href={url}
            event={{ grouping: 'Main Menu', category: 'Menu Item' }}
          >
            <div key={id} className={cx('HeaderMenu__link__text__', position)}>
              {name}
            </div>
          </LinkHandler>
        </div>
      )
    })
  }

  render () {
    return (
      <div className='HeaderMenu'>
        {this.renderLinks()}
      </div>
    )
  }
}

HeaderMenu.propTypes = {
  links: propTypeLinks,
  featureSettingsLoaded: PropTypes.bool
}

HeaderMenu.defaultProps = {
  links: {},
  featureSettingsLoaded: false
}

export { HeaderMenu }

export default withDisplayContext(HeaderMenu)
