import Analytics from './analyticsUtilities'
import ExternalLinkIcon from '../components/icons/ExternalLinkIcon'
import React from 'react'

export const shouldShowLink = (entry) => {
  return entry?.linkUrl || entry?.fieldRefWhatsnewInternalLink?.path?.alias || entry?.fieldWhatsnewExternalLink?.uri
}

export const renderLink = (entry) => {
  const LinkLabelText = entry?.fieldRefWhatsnewLinkLabel?.name
  const linkHandler = (event, url, newTab) => {
    event.preventDefault()
    event.stopPropagation()
    const trackingUrl = newTab ? url : (window.location.origin + url)
    Analytics.trackEvent({ event_name: newTab ? 'external_link_clicked' : 'internal_link_clicked', link_url: trackingUrl, link_type: 'link_other', text: entry?.title, parent_component_type: 'modal' }, window.utag)

    if (newTab) {
      window.open(url, '_blank')
    } else {
      window.location.href = url
    }
  }
  const linkUrl = entry?.linkUrl ? entry?.linkUrl : entry?.fieldRefWhatsnewInternalLink?.path?.alias
  switch (entry?.fieldWhatsnewUpdateOption) {
    case 'internal_link':
      return (linkUrl &&
        <a onClick={(e) => linkHandler(e, linkUrl, false)} target='_self'>{LinkLabelText}</a>)

    case 'external_link':
      return (entry?.fieldWhatsnewExternalLink?.uri &&
        <a onClick={(e) => linkHandler(e, entry?.fieldWhatsnewExternalLink?.uri, true)} target='_blank' rel='noreferrer'>{entry?.fieldRefWhatsnewLinkLabel?.name}{<ExternalLinkIcon />}</a>)

    default:
      return <></>
  }
}
