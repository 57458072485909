import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as XIcon } from '../../../assets/fontawesome/custom/x-icon.svg'
import { ReactComponent as StarIcon } from '../../../assets/fontawesome/custom/stars.svg'
import { getWhatsNewSettings, getWhatsNewEntries } from '../../../services/apiDomains/whatsNew'
import RichTextField from '../../common/RichTextField/RichTextField'
import dayjs from 'dayjs'
import Analytics from '../../../utilities/analyticsUtilities'
import { shouldShowLink, renderLink } from '../../../utilities/whatsNewHelpers'
import useDisplayContext from '../../../hooks/useDisplayContext'

import './WhatsNewSideDrawer.scss'

export const WhatsNewSideDrawer = (props) => {
  const SLIDE_IN_DELAY = 300
  const { onHide, show } = props

  const defaultFilters = [
    { display: 'All', value: 'All', selected: true },
    { display: 'Data Updates', value: 'Data Updates' },
    { display: 'New Products', value: 'New Products' },
    { display: 'Enhancements', value: 'Enhancements' }
  ]
  const { isMobileDevice } = useDisplayContext()

  const [whatsNewSettings, setWhatsNewSettings] = useState(null)
  const [whatsNewList, setWhatsNewList] = useState(null)
  const [filters, setFilters] = useState(defaultFilters)
  const [animateHide, setAnimateHide] = useState(isMobileDevice ? '' : 'show')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      if (!whatsNewSettings) {
        setIsLoading(true)
        const newSettings = await getWhatsNewSettings()
        setWhatsNewSettings(newSettings)
        const whatsNewListData = await getWhatsNewEntries(newSettings?.field_display_update_count)
        setWhatsNewList(whatsNewListData)
        updateFilters(whatsNewListData)
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const updateFilters = (entries) => {
    const validFilterStrings = ['All']

    entries.forEach(entry => {
      const filterName = entry.fieldRefWhatsnewFilters.name
      if (!validFilterStrings.includes(filterName)) {
        validFilterStrings.push(filterName)
      }
    })

    const validFilters = filters.filter(f => validFilterStrings?.includes(f.value))
    setFilters(validFilters?.map((filter) => {
      return { ...filter, visible: true }
    }))
  }

  const selectFilter = (selectedFilter) => {
    const updatedFilters = filters.map((filter) => {
      return {
        ...filter,
        selected: filter.value === selectedFilter.value
      }
    })
    setFilters(updatedFilters)
    Analytics.trackEvent({ event_name: 'button_engagement', link_type: 'link_other', text: selectedFilter.value }, window.utag)
  }
  const getSelectedFilter = () => {
    return filters?.find(filter => filter.selected)
  }

  const selectedFilter = getSelectedFilter()

  const handleClose = async () => {
    setAnimateHide('hide')
    await setTimeout(onHide, SLIDE_IN_DELAY)
  }

  return (
    <Modal
      className={`WhatsNewSideDrawer ${animateHide}`} size='xl'
      show={show}
      onHide={handleClose}
      animation={false}

    >

      <div className='WhatsNewtitle'>
        <div className='WhatsNewtitle__left'>
          <div><StarIcon /></div>
          <div>What's New</div>
        </div>
        <div className='WhatsNewtitle__right'>
          <button onClick={handleClose} aria-label='Close Window'>
            <XIcon />
          </button>
        </div>
      </div>

      {isLoading && <WhatsNewSkeleton />}
      {!isLoading && (
        <>
          <div className='WhatsNewBody'>
            <div className='WhatsNewBody__sortGroup'>
              {filters?.map((filter) => (
                filter?.visible &&
            (
              <div
                key={filter?.value}
                className={`WhatsNewBody__sortGroup__ContentTypeTab ${filter?.selected ? 'selected' : ''}`}
                onClick={() => { selectFilter(filter) }}
              >
                {filter?.display}
              </div>
            )
              ))}
            </div>
            {whatsNewList?.map((entry) => (
              (selectedFilter?.value === 'All' || selectedFilter?.value === entry?.fieldRefWhatsnewFilters?.name) &&
                <div key={entry?.id} className='WhatsNewBody__entry'>

                  {entry?.fieldWhatsnewSpotlight && (
                    <>
                      <div className='SpotLightTitle'>{whatsNewSettings?.field_spotlight_label}</div>
                    </>
                  )}

                  <div className='WhatsNewBody__entry__content'>
                    <div className='WhatsNewBody__entry__content__type'>{entry?.fieldRefWhatsnewUpdateType?.name}</div>
                    <div className='WhatsNewBody__entry__content__section'>
                      <div className='WhatsNewBody__entry__content__title'>
                        {!isLoading && entry?.title}
                      </div>
                      <div className='WhatsNewBody__entry__content__body'>
                        <RichTextField content={entry?.fieldWhatsnewDescription?.value} />
                      </div>
                    </div>
                  </div>
                  <div className='WhatsNewBody__entry__date'>{dayjs(entry?.fieldWhatsnewDate).format('MMMM D, YYYY')}</div>
                  {shouldShowLink(entry) && (
                    <div key={entry?.id} className='WhatsNewBody__entry__link'>{renderLink(entry)}</div>
                  )}
                </div>
            ))}
          </div>
        </>)}

    </Modal>
  )
}

export const WhatsNewSkeleton = () => {
  const skeletonRepeatCount = 4
  return (
    <div className='skeletonParent'>

      <div className='WhatsNewBody'>

        <div className='WhatsNewBody__sortGroup'>
          <div className='WhatsNewBody__sortGroup__ContentTypeTab skeleton-box first' />
          <div className='WhatsNewBody__sortGroup__ContentTypeTab skeleton-box' />
          <div className='WhatsNewBody__sortGroup__ContentTypeTab skeleton-box' />
          <div className='WhatsNewBody__sortGroup__ContentTypeTab skeleton-box' />
        </div>

        {Array.from({ length: skeletonRepeatCount }, (_, index) => (

          <div key={index} className='WhatsNewBody__entry'>
            <div className='WhatsNewBody__entry__content'>
              <div className='WhatsNewBody__entry__content__type skeleton-box' />
              <div className='WhatsNewBody__entry__content__section'>
                <div className='WhatsNewBody__entry__content__title skeleton-box' />
                <div className='WhatsNewBody__entry__content__body skeleton-box' />
                <div className='WhatsNewBody__entry__content__body continued skeleton-box' />
              </div>
            </div>
            <div className='WhatsNewBody__entry__date skeleton-box' />
            <div className='WhatsNewBody__entry__link skeleton-box' />
          </div>

        ))}
      </div>
    </div>
  )
}

WhatsNewSideDrawer.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.func
}
export default WhatsNewSideDrawer
