import React, { useRef, useState, useEffect, useCallback, Suspense } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { ReactComponent as BarsIcon } from '../../../assets/fontawesome/solid/bars.svg'
import { CSSTransition } from 'react-transition-group'
import Alert from '../../Alert/Alert'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import cmsHeaderLogo from '../../../assets/header/cms-header-logo.svg'
import cmsHeaderLogoWithText from '../../../assets/header/cms-header-logo-with-text.svg'
import withGlobalMenus, { globalMenuProps } from '../../../hocs/withGlobalMenus/withGlobalMenus'
import { useLocation } from 'react-router-dom'
import siteStore, { sessionStorageKey } from '../../../stores/siteStore'
import { trackMobileMenuClick, trackMenuSearchIconClick } from '../../../utilities/analyticsEventHelpers'
import { encodeSearchPageQueryString } from '../../../utilities/searchPageHelpers'
import QuickSearch from './QuickSearch'
import CloseIcon from '../../icons/CloseIcon'
import SearchIcon from '../../icons/SearchIcon'
import { propTypeLinks } from './propTypes'
import { getHomePagePath, getSearchPagePath, isPreviewUrl } from '../../../utilities/routeHelpers'
import useDisplayContext from '../../../hooks/useDisplayContext'
import { HeaderMenu } from './HeaderMenu'
import cx from 'classnames'
import config from '../../../config'
import LoadingCover from '../../common/LoadingCover/LoadingCover'
import animationTimeAndHeight from './Header.scss'
import { featuresAreLoaded } from '../../../stores/siteStore/siteStore'

const WatermarkOverlay = React.lazy(() => import('../../common/WatermarkOverlay/WatermarkOverlay'))
const SmallBanner = React.lazy(() => import('../../common/SmallBanner/SmallBanner'))

const { defaultSearchSortValue } = config
const mobileHeaderHeight = parseInt((animationTimeAndHeight.mobileHeaderHeightPx || '77px').split('px')[0], 10)

const Header = (props) => {
  const {
    history,
    headerMenuLinks,
    onOpen,
    onClose
  } = props

  const {
    alertHeight,
    showAlert,
    govBannerHeight
  } = siteStore.useState(state => ({
    alertHeight: state.alertHeight,
    showAlert: state.showAlert,
    govBannerHeight: state.govBannerHeight,
    quickSearchOpen: state.quickSearchOpen
  }))
  const featuresLoaded = featuresAreLoaded()
  const headerRef = useRef(null)
  const [isHeaderMenuOpen, setIsHeaderMenuOpen] = useState(false)
  const [isQuickSearchOpen, setIsQuickSearchOpen] = useState(false)
  const location = useLocation()
  const isHomePage = location.pathname === getHomePagePath() || location.pathname === `${getHomePagePath()}/`

  const measuredRef = useCallback(node => {
    if (node !== null) {
      siteStore.update(state => {
        state.alertHeight = node.getBoundingClientRect().height
      })
    }
  }, [])

  const handleClick = () => {
    window.sessionStorage.setItem(sessionStorageKey, 'false')
    siteStore.update(state => {
      state.showAlert = false
    })
  }

  useEffect(() => {
    siteStore.update(state => {
      state.quickSearchOpen = isQuickSearchOpen
    })
  }, [isQuickSearchOpen])

  useEffect(() => {
    // close the nav menu + quicksearch whenever the route changes
    history.listen((_location, action) => {
      if (['PUSH', 'POP', 'REPLACE'].includes(action)) {
        setIsHeaderMenuOpen(false)
        setIsQuickSearchOpen(false)
      }
    })
  }, [history])

  useEffect(() => {
    if (isHeaderMenuOpen) {
      // add this body class to make this component behave like a
      // modal; that is, the page content behind it is not scrollable
      onOpen()
      document.body.classList.add('header-menu-open')
    } else {
      onClose()
      document.body.classList.remove('header-menu-open')
    }
  }, [isHeaderMenuOpen, onOpen, onClose])

  function handleRequestSearch (searchValue) {
    const queryString = encodeSearchPageQueryString({
      sort: searchValue ? defaultSearchSortValue : '',
      keywords: searchValue
    })

    history.push({
      pathname: getSearchPagePath(),
      search: queryString
    })

    // close out quick search after navigation
    setIsQuickSearchOpen(false)
  }

  function handleMenuIconClick () {
    trackMobileMenuClick(isHeaderMenuOpen, window.utag)
    setIsHeaderMenuOpen(isCurrentlyOpen => !isCurrentlyOpen)
    // ensure both menus are not open at once
    setIsQuickSearchOpen(false)
  }

  function handleSearchIconClick () {
    let eventData = `Search Icon - ${!isQuickSearchOpen ? 'open' : 'closed'}`
    const path = history.location.pathname
    if (path === getSearchPagePath()) {
      eventData = 'Search Icon - refresh'
      // if on search page, refresh with empty params and jump to top of page
      handleRequestSearch('')
      window.scrollTo(0, 0)
    } else {
      setIsQuickSearchOpen(!isQuickSearchOpen)
      // ensure both menus are not open at once
      setIsHeaderMenuOpen(false)
    }
    trackMenuSearchIconClick(eventData, window.utag)
  }

  const { isMobile } = useDisplayContext()

  const renderSearchCol = () => {
    return (
      <div
        className={cx('Header__search_col', { open: isQuickSearchOpen })}
        onClick={e => handleSearchIconClick(e)}
      >
        <div className='Header__menu-search'>
          {!isQuickSearchOpen && <SearchIcon />}
          {isQuickSearchOpen && <CloseIcon size={20} />}
        </div>
      </div>
    )
  }

  const renderDesktopHeader = () => {
    return (
      <>
        {isPreviewUrl() && <Suspense fallback={<LoadingCover />}>{createPortal(<WatermarkOverlay watermarkText='PREVIEW' />, document.body)}</Suspense>}
        {isPreviewUrl() && <Suspense fallback={<LoadingCover />}><SmallBanner title='THIS IS A PREVIEW' text='' cssClassOverride='CenterBanner' /></Suspense>}
        <div className='Header__main'>
          <div className='Header__branding_col'>
            <LinkHandler noBs href={getHomePagePath()} ariaLabel='Centers for Medicare & Medicaid Services Data home'>
              <img src={cmsHeaderLogoWithText} alt='Centers for Medicare & Medicaid Services Data' />
            </LinkHandler>
          </div>
          <div className='Header__menu_col'>
            <HeaderMenu links={headerMenuLinks} featureSettingsLoaded={featuresLoaded} />
          </div>
          {renderSearchCol()}
        </div>
      </>
    )
  }

  /**
   Render the mobile version of the header.
   Mobile version is ordered as follows: Menu, Branding, Search
   It uses a hamburger menu that opens to fill the screen.
   */
  const renderMobileHeader = () => {
    const headerMenuContainerTop = (showAlert && isHomePage)
      ? `${alertHeight + mobileHeaderHeight + govBannerHeight}px`
      : `${mobileHeaderHeight + govBannerHeight}px`
    return (
      <div className='Header__main '>
        <div className={cx('Header__menu_col', { open: isHeaderMenuOpen })}>

          <button
            className='Header__menu-button'
            onClick={e => handleMenuIconClick(e)}
          >
            {!isHeaderMenuOpen && <BarsIcon />}
            {isHeaderMenuOpen && <CloseIcon size={20} />}
          </button>

        </div>
        <div className='Header__branding_col'>
          <LinkHandler noBs href={getHomePagePath()} ariaLabel='Centers for Medicare & Medicaid Services Data home'>
            <img src={cmsHeaderLogo} alt='Centers for Medicare & Medicaid Services Data' />
          </LinkHandler>
        </div>
        {renderSearchCol()}
        {createPortal(
          <CSSTransition
            in={isHeaderMenuOpen}
            classNames='headerMenuAnimation'
            timeout={parseInt(animationTimeAndHeight.headerAnimationTime, 10) || 500}
            unmountOnExit
          >
            <div className='Header__HeaderMenu_container' style={{ top: headerMenuContainerTop }}>
              <HeaderMenu
                links={headerMenuLinks}
                featureSettingsLoaded={featuresLoaded}
              />
            </div>
          </CSSTransition>,
          document.body
        )}
        {createPortal(
          <CSSTransition
            in={isHeaderMenuOpen}
            classNames='headerMenuOverlayAnimation'
            timeout={parseInt(animationTimeAndHeight.headerOverlayAnimationTime, 10) || 500}
            unmountOnExit
          >
            <div className='Header__overlay' onClick={() => setIsHeaderMenuOpen(false)} />
          </CSSTransition>,
          document.body
        )}
      </div>
    )
  }

  const position = isMobile && isHeaderMenuOpen ? 'fixed' : 'sticky'
  return (
    <header style={{ position }} ref={headerRef} className={cx('Header', { Header__menuOpen: isHeaderMenuOpen })}>
      {isHomePage && <Alert closeAlert={handleClick} ref={measuredRef} />}
      {isMobile && renderMobileHeader()}
      {!isMobile && renderDesktopHeader()}
      <QuickSearch
        isOpen={isQuickSearchOpen}
        headerDomNode={headerRef.current}
        onRequestClose={() => setIsQuickSearchOpen(false)}
        onRequestSearch={e => handleRequestSearch(e)}
        govBannerHeight={govBannerHeight}
      />
    </header>
  )
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  headerMenuLinks: propTypeLinks
}

Header.defaultProps = {
  onOpen: () => {},
  onClose: () => {}
}

export default withGlobalMenus([
  globalMenuProps.headerMenuLinks
])(Header)

export { Header }
