import axios from 'axios'
import config from '../../config'
import simpleCacheBust from '../../utilities/simpleCacheBust'
import log from '../../log'

const LINK_TYPES_TO_COMBINE = ['node--topic_page', 'node--subtopic_page']
const {
  apiSite,
  useCacheBuster
} = config

/**
 * Get settings for What's New feature on home page
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getWhatsNewSettings = async ({ cancelToken } = {}) => {
  try {
    const res = await axios({
      operationId: 'getWhatsNewSettings',
      method: 'GET',
      baseURL: apiSite,
      jsonAPI: true,
      url: '/jsonapi/node/whats_new_setup',
      params: {
        'fields[node--whats_new_setup]': 'title,field_display_update_count,field_spotlight_label,field_whatsnew_setup_description,field_ref_spotlight_image,field_stay_tuned_description,field_stay_tuned_heading,field_ref_stay_tuned_image',
        include: ['field_ref_spotlight_image.field_media_image', 'field_ref_stay_tuned_image.field_media_image'].join(','),
        ...(useCacheBuster.getDatasetById ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken: cancelToken
    })
    return parseWhatsnewSettings(res)
  } catch (error) {
    log.error('getWhatsNewSettings: request error', error)
    throw (error)
  }
}

/**
 * Get data for What's New feature on home page
 *  * @param {*} [opts.limit] number of entries to grab max
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getWhatsNewEntries = async (limit, { cancelToken } = {}) => {
  try {
    const findTopicPageNode = (response, type, id) => {
      return response?.rawData?.included?.find((e) => {
        if (e.id === id && e.type === type) { return e }
      })
    }

    const findUrlAlias = (response, topicPageNode) => {
      const refTopicId = topicPageNode?.relationships?.field_ref_topic?.data?.id
      const navigationalTopic = response?.rawData?.included?.find(
        (e) => e.type === 'taxonomy_term--navigational_topics' && e.id === refTopicId)
      return navigationalTopic?.attributes?.path?.alias
    }

    const resolveLinks = (response) => {
      const urlMappings = []
      res?.rawData?.data?.forEach((d) => {
        const linkId = d?.relationships?.field_ref_whatsnew_internal_link?.data?.id
        const linkType = d?.relationships?.field_ref_whatsnew_internal_link?.data?.type
        if (LINK_TYPES_TO_COMBINE.includes(linkType)) {
          const topicPageNode = findTopicPageNode(response, linkType, linkId)
          const finalURL = findUrlAlias(response, topicPageNode)
          urlMappings.push({ id: d?.id, url: finalURL })
          d.internalLinkCombinedUrl = finalURL
        }
      })
      const formattedData = res?.data?.map((d) => {
        const urlMapping = urlMappings?.find(u => u.id === d.id)
        return { ...d, linkUrl: urlMapping?.url || null }
      })
      return formattedData
    }

    const res = await axios({
      operationId: 'getWhatsNewEntries',
      method: 'GET',
      baseURL: apiSite,
      jsonAPI: true,
      url: '/jsonapi/node/whats_new_update',
      params: {
        'fields[node--whats_new_update]': 'title,field_whatsnew_update_option,field_whatsnew_description,field_whatsnew_date,field_whatsnew_spotlight,field_whatsnew_external_link,field_ref_whatsnew_internal_link,field_ref_whatsnew_link_label,field_ref_whatsnew_update_type,field_ref_whatsnew_filters',
        'fields[taxonomy_term--whats_new_filter_category]': 'name',
        'fields[taxonomy_term--whats_new_link_label]': 'name',
        'fields[taxonomy_term--dataset]': 'path',
        'fields[node--story_page]': 'path',
        'fields[node--look_up_tool]': 'path',
        'fields[node--api_page]': 'path',
        'fields[node--subtopic_page]': 'field_ref_topic',
        'fields[node--topic_page]': 'field_ref_topic',
        'fields[taxonomy_term--navigational_topics]': 'path',
        include: ['field_ref_whatsnew_internal_link.field_ref_topic,field_ref_whatsnew_link_label,field_ref_whatsnew_update_type,field_ref_whatsnew_filters'
        ].join(','),
        sort: '-field_whatsnew_spotlight,-field_whatsnew_date,title',
        'page[limit]': limit,
        'filter[status][value]': 1,
        ...(useCacheBuster.getDatasetById ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken: cancelToken
    })

    return resolveLinks(res)
  } catch (error) {
    log.error('getWhatsNewEntries: request error', error)
    throw (error)
  }
}

const parseWhatsnewSettings = (apiResponseSettings) => {
  const attributes = apiResponseSettings?.rawData?.data[0]?.attributes
  const relationships = apiResponseSettings?.rawData?.data[0]?.relationships
  const included = apiResponseSettings?.rawData?.included

  const getFileId = (imageId) => included
    .find((item) => item?.type === 'media--media_image' && item?.id === imageId)
    ?.relationships?.field_media_image?.data?.id

  const getFileUrl = (fileId) => apiSite + included
    .find((item) => item?.type === 'file--file' && item?.id === fileId)
    ?.attributes?.uri?.url

  // Grab the media IDs to link to file
  const spotImageId = relationships?.field_ref_spotlight_image?.data?.id
  const stayTunedImageId = relationships?.field_ref_stay_tuned_image?.data?.id

  // Get the file ids from included
  const spotlightFileId = getFileId(spotImageId)
  const stayTunedFileId = getFileId(stayTunedImageId)

  // Grab the URL from the media field_media_image entity
  const spotLightUrl = getFileUrl(spotlightFileId)
  const stayTunedUrl = getFileUrl(stayTunedFileId)

  return { ...attributes, spotLightUrl, stayTunedUrl }
}
